<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Daromadlar Regisratsiya</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="6" class="pl-0 py-0">
                <v-text-field
                  :error-messages="income_amountErrors"
                  v-model="income_amount"
                  dense
                  v-currency="options"
                  label="Summa"
                  outlined
                  :success="income_amount !== ''"
                  @input="$v.income_amount.$touch()"
                  @blur="$v.income_amount.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-0 py-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :error-messages="oper_dateErrors"
                      v-model="oper_date"
                      label="Sana"
                      dense
                      append-icon="event"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :success="oper_date !== ''"
                      @input="$v.oper_date.$touch()"
                      @blur="$v.oper_date.$touch()"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="oper_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="pl-0 py-0"></v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-autocomplete
                  :error-messages="mainIncomeErrors"
                  @input="$v.mainIncome.$touch()"
                  @blur="$v.mainIncome.$touch()"
                  @change="changeMainIncome"
                  :items="mainProfitSubCategory"
                  :success="
                    mainIncome !== '' &&
                    mainIncome !== null &&
                    mainIncome !== undefined
                  "
                  v-model="mainIncome"
                  item-text="name"
                  dense
                  return-object
                  item-value="id"
                  label="Asosiy Kirim"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-autocomplete
                  dense
                  :error-messages="mainIncomeCategoryErrors"
                  @input="$v.mainIncomeCategory.$touch()"
                  @blur="$v.mainIncomeCategory.$touch()"
                  @change="changeMainIncomeCategory"
                  :items="mainIncomeCategoryList"
                  v-model="mainIncomeCategory"
                  :success="
                    mainIncomeCategory !== '' &&
                    mainIncomeCategory !== null &&
                    mainIncomeCategory !== undefined
                  "
                  item-text="name"
                  return-object
                  item-value="id"
                  no-data-text="Malumot topilmadi"
                  label="Kategoriya"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pl-0 py-0">
                <v-autocomplete
                  dense
                  :error-messages="mainIncomeSubCategoryErrors"
                  @input="$v.mainIncomeSubCategory.$touch()"
                  @blur="$v.mainIncomeSubCategory.$touch()"
                  :items="mainIncomeSubCategoryList"
                  v-model="mainIncomeSubCategory"
                  :success="
                    mainIncomeSubCategory !== '' &&
                    mainIncomeSubCategory !== null &&
                    mainIncomeSubCategory !== undefined
                  "
                  no-data-text="Malumot topilmadi"
                  :hide-no-data="true"
                  item-text="name"
                  item-value="id"
                  return-object
                  label="SubKategoriya"
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" class="pl-0 py-0">
                <v-textarea
                  outlined
                  label="Izoh"
                  v-model="comment"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
          <div class="d-flex justify-content-start border-top pt-10">
            <div class="mr-2">
              <v-btn color="success" :disabled="incomeLoading" @click="save">
                <i class="el-icon-loading"></i>Saqlash</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { required } from 'vuelidate/lib/validators'
export default {
  data() {
    return {
      incomeLoading: false,
      mainIncomeSubCategory: null,
      mainIncomeCategory: '',
      mainIncomeSubCategoryList: [],
      mainIncomeCategoryList: [],
      mainIncome: '',
      oper_date: '',
      income_amount: '',
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999]
    }
  },
  validations: {
    oper_date: {
      required
    },
    mainIncome: {
      required
    },
    mainIncomeCategory: {
      required
    },
    income_amount: {
      required
    },
    mainIncomeSubCategory: {
      required
    }
  },
  created() {
    this.$store.dispatch('getMainProfitSubCategorySpecial')
  },
  watch: {
    mainExp: function (val) {
      this.category = val.expenditurecategory_set
    },
    expCategory: function (val) {
      if (val !== null) this.category2 = val.expendituretype_set
    }
  },
  computed: {
    mainProfitSubCategory() {
      return this.$store.state.requests.mainProfitSubCategorySpecial
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    oper_dateErrors() {
      const errors = []
      if (!this.$v.oper_date.$dirty) return errors
      !this.$v.oper_date.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainIncomeErrors() {
      const errors = []
      if (!this.$v.mainIncome.$dirty) return errors
      !this.$v.mainIncome.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainIncomeCategoryErrors() {
      const errors = []
      if (!this.$v.mainIncomeCategory.$dirty) return errors
      !this.$v.mainIncomeCategory.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    income_amountErrors() {
      const errors = []
      if (!this.$v.income_amount.$dirty) return errors
      !this.$v.income_amount.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    mainIncomeSubCategoryErrors() {
      const errors = []
      if (!this.$v.mainIncomeSubCategory.$dirty) return errors
      !this.$v.mainIncomeSubCategory.required &&
        errors.push("To'ldirilishi shart bo'lgan qator")
      return errors
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: false,
        valueAsInteger: false,
        allowNegative: false
      }
    }
  },
  filters: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Daromadlar' }])
  },
  methods: {
    changeMainIncome() {
      this.mainIncomeCategoryList = []
      this.mainIncomeCategoryList = [...this.mainIncome.incomecategory_set]
    },
    changeMainIncomeCategory() {
      this.mainIncomeSubCategoryList = []
      this.mainIncomeSubCategoryList = [
        ...this.mainIncomeCategory.incometype_set
      ]
    },
    toFloat(val) {
      if (typeof val == 'string') {
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else return val
    },
    save() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        Swal.fire({
          title: 'Xato toldirilgan',
          text: "Ma'lumotlar yuborilmadi!",
          icon: 'error',
          confirmButtonClass: 'btn btn-secondary'
        })
      } else {
        this.submitStatus = 'PENDING'
        this.submitStatus = 'OK'
        const data = {
          income_amount: this.toFloat(this.income_amount),
          oper_date: this.oper_date,
          comment: this.comment,
          income_maintype: this.mainIncome.id,
          income_category: this.mainIncomeCategory.id,
          income_type:
            // eslint-disable-next-line no-extra-boolean-cast
            Boolean(this.mainIncomeSubCategory)
              ? this.mainIncomeSubCategory.id
              : null
        }
        this.incomeLoading = true
        this.$store
          .dispatch('createIncome', data)
          .then(() => {
            this.incomeLoading = false
          })
          .catch((err) => {
            this.incomeLoading = false
            console.error(err)
          })
      }
    }
  }
}
</script>
